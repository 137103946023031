import React, {Component} from 'react'
import Header from "../components/Header";
import Intro from "../components/Intro";
import {Pastry} from "../components/Pastry";
import PracticalInfo from "../components/PracticalInfo";
import {akClient} from "../data/Api";
import Focus from "../components/Focus";

class MainPage extends Component {

	constructor(props) {
		super(props)
		this.state = {
			categories: [],
			blocks: [],
			showCategory: null,
		}
		this.smoothScroll = this.smoothScroll.bind(this)
	}
	smoothScroll(ev, e) {
		ev.preventDefault()
		this.setState({
			showCategory: e
		})
	}
	componentDidMount() {
		akClient.getBlocks().then((result) => {
			if (result !== undefined) {
				akClient.getCategories().then((categories) => {
					if (categories !== undefined && !equalCategories(categories,this.state.categories)) {
						this.setState({
							categories: categories,
							blocks:result,
						})
					}
				})
			}
		})
	}

	render() {
		return (
			<>
				<Header
					categories={this.state.categories}
					smoothScroll={this.smoothScroll}
				/>
				<Intro
					categories={this.state.categories}
					smoothScroll={this.smoothScroll}
				/>
				{/*<Focus />*/}
				{this.state.categories.length > 0 &&  <Pastry
					blocks={this.state.blocks}
					showCategory={this.state.showCategory}
					resetShowCategory={() => this.setState({showCategory: null})}
					categories={this.state.categories}
					smoothScroll={this.smoothScroll}
				/>}
				<PracticalInfo blocks={this.state.blocks}/>
			</>
		)
	}
}

export default MainPage

function equalCategories(array1, array2) {
	const array2Sorted = array2.slice().sort();
	return array1.length === array2.length && array1.slice().sort().every(function(value, index) {
		return value.title === array2Sorted[index].title;
	});
}