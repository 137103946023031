import React, {Component} from 'react'
import PastryItem from "./PastryItem";
import {akClient} from "../data/Api";
import {Product} from "./Commerce";
import {camelize} from "../App";

export default class PastryCategorie extends Component {
	constructor(props) {
		super(props);
		this.state = {items: []}
	}

	componentDidMount() {
		this.getPastryItems(this.props.category.id)

	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.focus) {
			const labelElement = document.querySelector(
				`#` + camelize(this.props.category.title)
			)
			if (labelElement) {
				// When there is an input, scroll this input into view.
				setTimeout(function(){
					labelElement.scrollIntoView({behavior: 'smooth'})
					prevProps.resetFocus()
				}, 100)
			}
		}
	}

	getPastryItems(id) {
		// Products were set from cache, refresh using latest values.
		akClient.getProducts(id).then((result) => {
			if (result !== undefined && !equalCategory(result,this.state.items)) {
				this.setState({
					items: result
				})
			}
		})
	}

	render() {
		return <>
			<div id={camelize(this.props.category.title)} className="pastryCategorie container">
				<div className="row">
					<h3>{this.props.category.title}</h3>
				</div>
				<div className="row">
					{this.state.items.map(function (item, key) {
						let product = new Product(
							item.name,
							item.description
						)
						product.addImages(item.images)
						if (item.hasOwnProperty("cover")) {
							product.setCover(item.cover.src, item.cover.target)
						}
						if (item.hasOwnProperty("pricing")) {
							product.pricing = item.pricing.map(function (item) {
								return {
									"unit": item.unit,
									"price": item.price,
									"units": item.units,
									"onDemand": item.on_demand,
								}
							})
						}
						return <PastryItem
							key={key}
							product={product}
							toggleShowOrderPage={this.props.toggleShowOrderPage}
						/>
					}, this)}
				</div>
			</div>
		</>
	}
}

function equalCategory(array1, array2) {
	const array2Sorted = array2.slice().sort();
	return array1.length === array2.length && array1.slice().sort().every(function(value, index) {
		return value.name === array2Sorted[index].name && value.cover === array2Sorted[index].cover;
	});
}
