import React from 'react';
import './App.scss';
import Footer from "./components/Footer";
import AppRouter from "./AppRouter";

export default class App extends React.Component {
	render() {
		return (
			<>
				<AppRouter />
				<Footer />
			</>
		);
	}

}

// export function ImportImages(r) {
// 	let images = {};
// 	r.keys().map((item) => (console.log(item), images[item.replace('./', '')] = r(item).default));
// 	return images;
// }

export function camelize(str) {
	return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
		return index === 0 ? word.toLowerCase() : word.toUpperCase();
	}).replace(/\s+/g, '');
}
