import React, {Component} from 'react'
import {camelize} from "../App";
import '../styles/intro.scss';

import logoImg from '../assets/img/logo/CakeLogo.png'


class Intro extends Component {
	render() {
		return <section id="home" className="intro intro-bg">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 col-md-12 caption-two-panel ml-auto">
						<img
							src={logoImg} alt="Ateljee Kaffee" width="70%"/>
					</div>
					<div className="col-lg-6 col-md-12 caption-two-panel ml-auto">

						<div className="intro-caption">
							<h1>Ateljee Kaffee</h1>
							<div className="mt-4 mb-4"><h2>Th<div style={{display:"inline-block"}}></div>ema- en bruidstaarten, desserts en gebak</h2>
							</div>
							<p className="mb-4">Ons gebak wordt à la minute gemaakt, steeds met verse producten en lactose-vrij indien nodig.</p>
							<p className="mb-4">Bekijk ons <b>assortiment</b>, check onze social media voor inspiratie, of <b>contacteer</b> ons met je wensen.</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-6 col-md-12 caption-two-panel ml-auto">
						<p className="mb-2 ml-auto socials mt-2">
							<a target="_blank" rel="noreferrer" href="https://wa.me/+32479133921"><i className="fab fa-2x fa-whatsapp"></i></a>
							<a target="_blank" rel="noreferrer" href="https://www.facebook.com/ateljee.kaffee.dessertstudio"><i className="fab fa-2x fa-facebook"></i></a>
							<a target="_blank" rel="noreferrer" href="https://www.instagram.com/ateljeekaffee"><i className="fab fa-2x fa-instagram"></i></a>
						</p>
					</div>
					<div className="col-12 cta">
						<p className="mb-4 mt-4 ml-auto cta mt-3">
						{this.props.categories.map(function (item, key) {
							return <a href={"/#"+camelize(item.title)} key={key} onClick={(ev) => this.props.smoothScroll(ev,item)} className="btn btn-cta">{item.title}</a>
						}, this)}
						</p>
					</div>
				</div>
			</div>
		</section>
	}
}

export default Intro
