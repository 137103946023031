import "../styles/overlay.scss"
import React from "react";

export function Overlay(props) {
		return (
			<div className="overlay-layer" onClick={props.closeHandler}>
				<div className="overlay-body">
					{props.children}
				</div>
			</div>
		)

}
