import React, {Component} from 'react'
import {camelize} from "../App";
import '../styles/header.scss';
import logoImg from '../assets/img/logo/brandname_transparent_dark.png'

class Header extends Component {
	constructor(props) {
		super(props)
		this.state = {headerClass: ""}
	}
	componentDidMount() {
		window.addEventListener('scroll', this.onScroll)
	}
	onScroll = () => {
		const offset = window.scrollY //Don't get confused by what's scrolling - It's not the window
		var headerClass = ""
		if (offset > 350) {
			headerClass = "light-header"
		}
		this.setState({
			headerClass: headerClass
		})
	}
	render() {
		return <nav className={`${this.state.headerClass} navbar navbar-expand-md fixed-top top-nav`} onScroll={this.onScroll}>
			<div className="container-fluid">
				<div className="navbar-brand navbar-brand-small" ><img
					src={logoImg} alt="Ateljee Kaffee" width="200"/></div>
				<ul className="navbar-nav">
					{this.props.categories.map(function (item, key) {
						return <li key={key}>
							<a href={"/#"+camelize(item.title)} key={key} onClick={(ev) => this.props.smoothScroll(ev,item)} className="nav-link btn btn-primary">{item.title}</a>
						</li>
					}, this)}
					<li className="navbar-socials">
						<a target="_blank" rel="noreferrer" href="https://www.instagram.com/ateljeekaffee"><i className="fab fa-2x fa-instagram"></i></a>
						<a target="_blank" rel="noreferrer" href="https://www.facebook.com/ateljee.kaffee.dessertstudio"><i className="fab fa-2x fa-facebook"></i></a>
						<a target="_blank" rel="noreferrer" href="https://wa.me/+32479133921"><i className="fab fa-2x fa-whatsapp"></i></a>
					</li>
				</ul>

			</div>
		</nav>
	}
}

export default Header
