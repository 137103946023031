import React, {Component} from "react";
import logoImg from "../assets/img/logo/brandname_transparent_dark.png"

class PageHeader extends Component {
	render() {
		return (
			<nav className={`light-header navbar navbar-expand-md fixed-top top-nav`}>
				<div className="container-fluid">
					<div className="navbar-brand navbar-brand-small"><img
						src={logoImg} alt="brandname" width="200"/>
					</div>
				</div>
			</nav>
		)
	}
}

export default PageHeader;
