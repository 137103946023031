import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAnglesRight} from "@fortawesome/free-solid-svg-icons";

export default function ImageSlider(props) {
	const [idx, setIdx] = useState(0)
	function next(e) {
		e.stopPropagation()
		if (idx === props.images.length -1) {
			setIdx(0)
		} else {
			setIdx(idx+1)
		}
	}
	return (
		<>
			<div className={`${props.images.length === 1 ? 'short' : 'full'} images`}>
				{props.images.map(function (item, key) {
					return key === idx &&  <img key={item + key} alt={props.alt} src={item}/>
				})}
				<div className={'label'}>{props.alt}</div>
			</div>
			{props.images.length > 1 && <div className={"flex-row controls"}>
				<div onClick={next}><FontAwesomeIcon icon={faAnglesRight}/></div>
			</div>}
		</>
	)
}
