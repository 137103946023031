import React, {useCallback, useEffect, useRef, useState} from 'react'
import '../styles/pastry.scss';
import PastryCategorie from "./PastryCategorie";
import {Overlay} from "./Overlay";


export function Pastry(props) {
        const loader = useRef(null);
        const [showIndex, setShowIndex] = useState(0)
        const [showOrderPage, setShowOrderPage] = useState(false)
        const handleObserver = useCallback((entries) => {
            const target = entries[0];
            if (target.isIntersecting) {
               setShowIndex((prev) => prev + 1);
            }
        }, []);

        useEffect(() => {
            if (props.showCategory) {
                for (const [key, value] of Object.entries(props.categories)) {
                    if (value.id === props.showCategory.id && showIndex < key) {
                        setShowIndex(key);
                    }
                }
            }
        }, [props.showCategory, props.categories, showIndex])

        useEffect(() => {
            const option = {
                root: null, rootMargin: "200px", threshold: 0
            };
            const observer = new IntersectionObserver(handleObserver, option);
            if (loader.current) observer.observe(loader.current);
        }, [handleObserver, showIndex]);

        function toggleShowOrderPage() {
            setShowOrderPage(!showOrderPage)
        }

        return (<section id="assortiment" className="pastry bg-overlay parallax">
            {showOrderPage && <Overlay closeHandler={toggleShowOrderPage}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3>Zin gekregen?</h3>
                        </div>
                        <div className="col-6">
                            {props.blocks['how-to-order'] != null && <h4>{props.blocks['how-to-order'].name}</h4>}
                            {props.blocks['how-to-order'] != null &&
                                <div dangerouslySetInnerHTML={{__html: props.blocks['how-to-order'].body}}/>}
                        </div>
                        <div className="col-6">
                            {props.blocks['take-away'] != null && <h4>{props.blocks['take-away'].name}</h4>}
                            {props.blocks['take-away'] != null &&
                                <div dangerouslySetInnerHTML={{__html: props.blocks['take-away'].body}}/>}
                        </div>
                    </div>
                </div>
            </Overlay>}
            {props.categories.map(function (item, idx) {
                return idx <= showIndex && <PastryCategorie
                        key={item.id}
                        focus={props.showCategory && props.showCategory.id === item.id}
                        resetFocus={props.resetShowCategory}
                        category={item}
                        smoothScroll={props.smoothScroll}
                        toggleShowOrderPage={toggleShowOrderPage}
                    />
            })}
            <div ref={loader}></div>
        </section>)
    }
