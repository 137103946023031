import axios from "axios";
import { setupCache } from "axios-cache-adapter"

export const akClient = axios.create({
	adapter: setupCache({
		maxAge: 48 * 60 * 1000
	}).adapter,
	baseURL: "https://admin.ateljeekaffee.be/",
});


akClient.getProducts = function(id) {
	return this.get("sites/default/files/backup/products_"+id+".json").then(response => {
		return response.data;
	});

}

akClient.getBlocks = function() {
	return this.get("sites/default/files/backup/blocks.json").then(response => {
		return response.data;
	});

}

akClient.getCategories= function() {
	return this.get("sites/default/files/backup/categories.json").then(response => {
		return response.data;
	});
}

akClient.refresh = function() {
	this.get("categories/list").then(response => {
		response.data.map(item => {
			return this.get("category/" + item.id + "/products")
		})
	})
	this.get("blocks/list")
}