import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, {Component} from 'react'
import MainPage from "./pages/MainPage";
import InfoPage from "./pages/InfoPage";
import {akClient} from "./data/Api";

export default class AppRouter extends Component {

	componentDidMount() {
		akClient.refresh()
	}

	render() {
		return (
			<BrowserRouter>
				<Routes>
					<Route path='*' element={<MainPage  {...this.props} />} />
					<Route path='info' element={<InfoPage  {...this.props} />} />
				</Routes>
			</BrowserRouter>
		)
	}
}


