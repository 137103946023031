export class Cart {
	items = []
	getPrice() {
		let p = 0
		for (const i in this.items) {
			p = p + this.items[i].price
		}
		return p
	}
	add(product) {
		this.items.push(product)
	}
}

export class Product {
	constructor (name, description) {
		this.name = name
		this.description = description
		this.images = [];
		this.pricing = [];
	}
	setCover(src, tgt) {
		this.cover = {
			"src": src,
			"target": tgt,
		}
	}
	addPricingOption(units, price, onDemand) {
		this.pricing.push({
			"units": units,
			"price": price,
			"onDemand": onDemand,
		})
		return this
	}
	addImage(image) {
		this.images.push(image)
		return this
	}
	addImages(images) {
		this.images = images
		return this
	}
}
