import React, {Component} from "react";
import PageHeader from "./PageHeader";
import PracticalInfo from "../components/PracticalInfo";
import PageNotFound from "../components/PageNotFound";

const contentBlockId = 'how-to-orsder'

class InfoPage extends Component {
	render() {
		return (
			<>
				<PageHeader />
				<section id="info" className="content bg-overlay parallax">
					<div className={"container"}>
						{this.props.blocks.hasOwnProperty(contentBlockId) ?
						<div dangerouslySetInnerHTML={{__html: this.props.blocks[contentBlockId].body}}/> :
							<PageNotFound />
						}
					</div>
				</section>
				<PracticalInfo
					blocks={this.props.blocks}
				/>
			</>
		)
	}
}

export default InfoPage;
