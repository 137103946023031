import React, {Component} from 'react'
import '../styles/practical.scss';

class PracticalInfo extends Component {

	render() {
		return <section id="practical" className="">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<h3>Zin gekregen?</h3>
					</div>
					<div className="col-6">
						{this.props.blocks['how-to-order'] != null && <h4>{this.props.blocks['how-to-order'].name}</h4>}
						{this.props.blocks['how-to-order'] != null &&
						<div dangerouslySetInnerHTML={{__html: this.props.blocks['how-to-order'].body}}/>}
					</div>
					<div className="col-6">
						{this.props.blocks['take-away'] != null && <h4>{this.props.blocks['take-away'].name}</h4>}
						{this.props.blocks['take-away'] != null &&
						<div dangerouslySetInnerHTML={{__html: this.props.blocks['take-away'].body}}/>}
					</div>
				</div>
			</div>
		</section>
	}
}

export default PracticalInfo;
