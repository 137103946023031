import React, {Component} from 'react'
import '../styles/footer.scss';

class Footer extends Component {
	render() {
		return <footer>
			<div className="bg-accent py-2">
				<div className="container text-left">
					<div className="row">
						<div className="col-lg-4">
							<div className="col-12">
								<h3>Openingstijden</h3>
								<div>Maandag t/m zondag</div>
								<div>Enkel op afspraak</div>
							</div>

						</div>
						<div className="col-lg-4">
							<div className="col-12">
								<h3>Adres</h3>
								<div>Veldhovenstraat 11</div>
								<div>3945 HAM</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="col-12">
								<h3>Contact</h3>
								{/*<div className="mt-3">*/}
								{/*	<a href="tel:+32486666666">+32 486 666666</a>*/}
								{/*</div>*/}
								<div className="mt-3">
									<a href="mailto:info@ateljeekaffee.be">info@ateljeekaffee.be</a>
								</div>
							</div>
							<div className="col-12">
								<div className="socials">
									<div>
										<a target="_blank" rel="noreferrer" href="https://www.instagram.com/ateljeekaffee"><i className="fab fa-2x fa-instagram"></i></a>
									</div>
									<div>
										<a target="_blank" rel="noreferrer" href="https://www.facebook.com/ateljee.kaffee.dessertstudio"><i className="fab fa-2x fa-facebook"></i></a>
									</div>
									<div>
										<a target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=0479133921"><i className="fab fa-2x fa-whatsapp"></i></a>
									</div>
								</div>
							</div>
							<div className="col-12 mt-2">
								<div>BTW 0809.668.797</div>
							</div>

						</div>
					</div>
				</div>
			</div>
			<div className="text-center">
				<div>Ateljee Kaffee  &copy;<span>{new Date().getFullYear()}</span>. Alle rechten voorbehouden.
				</div>
			</div>
		</footer>
	}
}

export default Footer
